export default {
  blogerName: 'AFFGOLD',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/affgold',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@betgoldteam1524',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@anonymousgold7010',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/cead99d81',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c91ef7134',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/ce46f900c',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cfec25761',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cdcd44072',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c6151bb86',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cb7b6090a',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c0868e8eb',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c92a93683',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/ca9087593',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BETGOLDTEAM</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BETGOLDTEAM',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
